/* Font family */

@font-face {
  font-family: "TT Norms Thin";
  src: url("/src/assets/fonts/TT-Norms-Fonts/TTNorms-Thin.otf");
}
@font-face {
  font-family: "TT Norms Regular";
  src: url("/src/assets/fonts/TT-Norms-Fonts/TTNorms-Regular.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Urbanist", sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}
.App {
  height: 100%;
  width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}

input[type="tel"]::-webkit-input-placeholder {
  color: #999; /* Couleur de l'invite du texte */
}

input {
  font-size: 1rem !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
